import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Box, CircularProgress } from "@mui/material";
import useAuth from "../../hooks/Auth/useAuth";
import axios from "axios";
import Swal from "sweetalert2";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const camerasList = [
  { camera_name: "left", show: true },
  { camera_name: "front", show: true },
  { camera_name: "right", show: true },
  { camera_name: "incabin", show: true },
];

export default function CartCamerasCarousel({ cartId }) {
  const { relevantUrl, relevantSite, sitesSockectsConnection, auth } = useAuth();
  const [cameraImages, setCameraImages] = useState([]);
  const [loading, setLoading] = useState(true);

  // Function to handle receiving cameras data from the socket
  const handleSocketData = (data) => {
    const { cameras_data_list } = JSON.parse(data);

    if (cameras_data_list && Array.isArray(cameras_data_list)) {
      const images = cameras_data_list
        .filter((camera) => camera.image_base64) // Only include cameras with images
        .map((camera) => ({
          name: camera.camera_name,
          image: `data:image/jpeg;base64,${camera.image_base64}`,
        }));

      setCameraImages(images);
      setLoading(false);
    }
  };

  useEffect(() => {
    // Listen for camera data from the server
    if (relevantSite && sitesSockectsConnection && sitesSockectsConnection[relevantSite]) {
      const relevantSiteSocket = sitesSockectsConnection[relevantSite];
      relevantSiteSocket.on("cartCamerasData", handleSocketData);
      return () => {
        relevantSiteSocket.emit("sm_turn_off_cameras_event", cartId);
        relevantSiteSocket.off("cartCamerasData");
      };
    }
  }, [sitesSockectsConnection, relevantSite]);

  useEffect(() => {
    // Request cameras data when the component mounts
    const requestCameras = async () => {
      try {
        const cameraCommandsList = camerasList.map((camera) => ({
          camera_name: camera.camera_name,
          show: camera.show,
        }));

        const response = await axios.post(`${relevantUrl}/admin/requestCameras`, {
          camera_commands_list: cameraCommandsList,
          cartId,
          adminId: auth?.userId || null,
        });

        if (response.status !== 200) {
          Swal.fire({
            icon: "error",
            title: "Failed to request cameras",
            text: response.data,
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.message,
        });
      }
    };

    if (relevantUrl) {
      setLoading(true);
      requestCameras();
    }
  }, [relevantUrl, cartId, auth]);

  // Carousel settings
  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  return (
    <Box style={{ width: "90%", maxWidth: "600px",height:'auto',padding:"20px",backgroundColor:"#f5f5f5",borderRadius:"10px"}}>
      {loading ? (
        <CircularProgress />
      ) : cameraImages.length > 0 ? (
        <Slider {...carouselSettings}>
          {cameraImages.map((camera, index) => (
            <div key={index} style={{ textAlign: "center" }}>
              <h4>
                {camera.name.charAt(0).toUpperCase() + camera.name.slice(1).toLowerCase()}
              </h4>
              <img
                src={camera.image}
                alt={`Camera - ${camera.name}`}
                style={{ width: "100%", maxHeight: "400px", objectFit: "contain" }}
              />
            </div>
          ))}
        </Slider>
      ) : (
        <p>No camera images available</p>
      )}
    </Box>
  );
}
