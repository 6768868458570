import React, { useState } from "react";
import { Modal, Box, Tooltip } from "@mui/material";
import { ReactComponent as MicIcon } from "../../assets/icons/general/microphone.svg";
import { ReactComponent as CallBtn } from "../../assets/icons/general/callBtn.svg";
import { ReactComponent as CenterLocIcon } from "../../assets/icons/map/centerLoc.svg";
import { useHistory } from "react-router-dom";

import AudioRecorderModal from "../audioRecorderModal/AudioRecorderModal"; // Recorder modal component
import useAuth from "../../hooks/Auth/useAuth.js";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  maxWidth: "90vw",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 44,
  height: "auto", // Allow height to adjust based on content
  maxHeight: "90vh", // Prevent the popup from getting too tall
  overflowY: "auto", // Allow scrolling if content exceeds max height
};

export default function CartActionButtons({ cartInfo,onFocusCB, }) {
  const [openRecorder, setOpenRecorder] = useState(false);
  const { relevantUrl, sitesSockectsConnection, relevantSite, cartsPaths } = useAuth();
  const history = useHistory();

  if (!cartInfo) return null;

  // Functions for opening and closing modals
  const openRecorderModal = (e) =>{
    e.preventDefault();
    e.stopPropagation();
    setOpenRecorder(true);
  }
  const closeRecorderModal = (e) =>{
    e.preventDefault();
    e.stopPropagation();
    setOpenRecorder(false);
  }

  // Function to call the active user
  const callToActiveUser = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const userId = cartsPaths[parseInt(cartInfo.cart_number)]?.mission?.userId;
    if (sitesSockectsConnection && relevantSite && userId) {
      const relevantSocket = sitesSockectsConnection[relevantSite];
      if (relevantSocket) {
        relevantSocket.emit("getUserDetails", userId);
      } else {
        console.log("No relevant socket found for the site");
      }
    } else {
      console.log("Missing data to make the call");
    }
  };

  // Function to focus on cart
  const focusOnCart = (cartId) => {
    history.push({
      pathname: "/dashboard_site",
      state: { cartID: cartId },
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        gap: "10px",
        padding: "10px",
        border: "1px solid #ddd",
        borderRadius: "8px",
        backgroundColor: "#f9f9f9",
      }}
    >
      {/* Record Audio Button */}
      <Tooltip title="Record Audio" placement="bottom">
        <span style={{ cursor: "pointer" }} onClick={openRecorderModal}>
          <MicIcon />
        </span>
      </Tooltip>

      { cartInfo?.cart_number && cartsPaths && cartsPaths[parseInt(cartInfo.cart_number)]?.mission?.userId &&    
      <Tooltip title="Call Active User" placement="bottom">
        <span style={{ cursor: "pointer" }} onClick={callToActiveUser}>
          <CallBtn />
        </span>
      </Tooltip>}

      {/* Focus on Cart Button */}
      <Tooltip title="Focus on Cart" placement="bottom">
        <span
          style={{ cursor: "pointer" }}
          onClick={(e) =>
            {
              onFocusCB(e);

              focusOnCart(cartInfo.cart_number);
            }}
        >
          <CenterLocIcon />
        </span>
      </Tooltip>

      {/* Recorder Modal */}
      <Modal
        open={openRecorder}
        onClose={closeRecorderModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={modalStyle}>
          <AudioRecorderModal
            baseUrl={relevantUrl}
            onClose={closeRecorderModal}
            cartNumber={cartInfo.cart_number}
          />
        </Box>
      </Modal>
    </div>
  );
}
