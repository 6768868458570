import React, { useState, useRef, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CircleIcon from '@mui/icons-material/Circle';
import PauseIcon from "@mui/icons-material/Pause";
import uploadTemporaryAudio from "../../services/uploadTemporaryAudio";
import renderCustomModalFunction from "../customModal/modalWrapper/renderCustomModalFunction";
import WaveSurfer from "wavesurfer.js";
import RecordPlugin from "wavesurfer.js/dist/plugins/record.esm.js";
import useAuth from "../../hooks/Auth/useAuth";

const AudioRecorderModal = ({ baseUrl, onClose, cartNumber }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState(null);
  const [recordingData, setRecordingData] = useState(null);
  const { isPhoneSize } = useAuth(); // Using the boolean from your auth hook

  // Define layout variables based on device size
  const progressCircleSize = isPhoneSize ? 40 : 60;   // Smaller circle on phone
  const waveformHeight = isPhoneSize ? 40 : 60;         // Adjust waveform height

  // Optional: adjust button styling for smaller devices
  const buttonPadding = isPhoneSize ? "6px 16px" : "8px 24px";
  const buttonFontSize = isPhoneSize ? "0.9rem" : "0.9rem";

  const waveformRef = useRef(null);
  const waveSurfer = useRef(null);
  const recordPlugin = useRef(null);
  const totalDuration = 30; // 30 seconds limit for recording
  const timerRef = useRef(null); // Timer for progress updates
  const autoStopRef = useRef(null); // Reference for auto-stop

  useEffect(() => {
    if (!waveformRef.current) return;

    // Create WaveSurfer instance
    waveSurfer.current = WaveSurfer.create({
      container: waveformRef.current,
      progressColor: "#009ddb",
      waveColor: '#009ddb',
      cursorColor: '#00a7ee33',
      height: 60,

      "cursorWidth": 0,
      "barWidth": 3,

    });

    // Register the Record plugin
    recordPlugin.current = waveSurfer.current.registerPlugin(
      RecordPlugin.create({
        renderRecordedAudio: true,
      })
    );

    // When recording ends, set the audio URL and save the blob data
    recordPlugin.current.on("record-end", (blob) => {
      const recordedUrl = URL.createObjectURL(blob);
      setAudioURL(recordedUrl);
      setRecordingData(blob);
    });

    return () => {
      if (waveSurfer.current) {
        waveSurfer.current.destroy();
      }
    };
  }, [waveformHeight]);

  const stopRecording = () => {
    recordPlugin.current.stopRecording();
    setIsRecording(false);
    clearInterval(timerRef.current);
    clearTimeout(autoStopRef.current);
  };

  const startRecording = () => {
    setIsRecording(true);
    setProgress(0);
    setUploadMessage(null);

    recordPlugin.current.startRecording();
    updateProgress();

    // Automatically stop recording after the total duration (30 seconds)
    autoStopRef.current = setTimeout(() => {
      setUploadMessage("Recording is up to 30 sec' ");
      stopRecording();
    }, totalDuration * 1000);
  };

  const updateProgress = () => {
    timerRef.current = setInterval(() => {
      setProgress((prev) => {
        if (prev < 100) {
          return prev + (100 / totalDuration);
        } else {
          clearInterval(timerRef.current);
          stopRecording();
          return 100;
        }
      });
    }, 1000);
  };

  const uploadRecordedFile = async () => {
    if (!recordingData) return;
    setIsUploading(true);
    setUploadMessage(null);

    try {
      const result = await uploadTemporaryAudio(baseUrl, cartNumber, recordingData);
      setUploadMessage(result === "error" ? "Failed send to cart audio file." : "Audio sent to cart successfully!");
    } catch (error) {
      setUploadMessage("Error sending audio file to cart.");
    } finally {
      setIsUploading(false);
    }
  };

  const confirmUpload = async () => {
    const options = {
      title: "Confirm Send",
      bodyText: "Are you sure you want to send this audio to the cart?",
      buttons: [
        {
          text: "Cancel",
          class: "cancelButton",
          closeModal: true,
          value: "cancel",
        },
        {
          text: "Confirm",
          class: "dangerButton",
          closeModal: true,
          callback: uploadRecordedFile,
          value: "confirm",
        },
      ],
    };
    await renderCustomModalFunction(options);
  };

  // Styled button component with dynamic styling
  const StyledButton = ({ label, onClick, className, disabled }) => (
    <div
      onClick={!disabled ? onClick : null}
      className={`customModalButton ${className}`}
      style={{
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        padding: buttonPadding,
        borderRadius: "20px",
        fontFamily: "Arial, sans-serif",
        fontSize: buttonFontSize,
        fontWeight: 600,
        cursor: disabled ? "not-allowed" : "pointer",
      }}
    >
      {label}
    </div>
  );

  return (
    <Box
      sx={{
        backgroundColor: "white",
        borderRadius: 2,
        mx: "auto", // centers the modal horizontally
        p:2,
      }}
    >
      {/* Title */}
      <Typography variant="h6" sx={{ mb: 2, textAlign: "left" }}>
        Record Audio
      </Typography>

      {/* Row with Circular Progress Bar and WaveSurfer visualization */}
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        {/* Circular Progress Bar with Play/Pause button */}
        <Box
          sx={{
            width: progressCircleSize,
            height: progressCircleSize,
            display: "flex",
            mr: 4,
            alignItems: "center",
          }}
        >
          <CircularProgressbarWithChildren
            value={progress}
            styles={buildStyles({
              pathColor: "#00b0ff",
              trailColor: "#d6d6d6",
            })}
          >
            <div onClick={isRecording ? stopRecording : startRecording}>
              {isRecording ? (
                <PauseIcon sx={{ color: "#00b0ff" }} />
              ) : (
                <CircleIcon style={{ width: progressCircleSize * 0.4, height: progressCircleSize * 0.4 }} sx={{ color: "#ff0000" }} />
              )}
            </div>
          </CircularProgressbarWithChildren>
        </Box>

        {/* WaveSurfer.js visualization */}
        <Box sx={{ flex: 1 }}>
          <div
            ref={waveformRef}
            style={{
              height: `${waveformHeight}px`,
              backgroundColor: "#f0f0f0",
              borderRadius: "40px",
            }}
          />
        </Box>
      </Box>

      {/* Row with audio preview */}
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end", mb: 4 }}>
        <Box sx={{ flex: 1 }}>
          <Typography
            sx={{
              fontFamily: "Arial, sans-serif",
              fontSize: "0.8rem",
              fontWeight: 700,
              color: !audioURL ? "gray" : "black",
            }}
          >
            Preview
          </Typography>
          <audio controls src={audioURL} disabled={!audioURL} style={{ width: "100%" }} />
        </Box>
      </Box>

      {/* Bottom Row with Cancel and Send buttons */}
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <StyledButton label="Cancel" onClick={onClose} className="cancelButton" disabled={false} />
        <StyledButton label={isUploading ? "Sending..." : "Send"} onClick={confirmUpload} className="dangerButton" disabled={isUploading || !audioURL} />
      </Box>

      {/* Server message */}
      {uploadMessage && (
        <Typography variant="body2" color={uploadMessage.includes("successfully") ? "green" : "red"} sx={{ mt: 2 }}>
          {uploadMessage}
        </Typography>
      )}
    </Box>
  );
};

export default AudioRecorderModal;
