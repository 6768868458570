import { useEffect, useRef, useState } from 'react';
import Peer from 'peerjs';
import Draggable from 'react-draggable'; 
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import Stack from '@mui/material/Stack';
import useAuth from '../../hooks/Auth/useAuth';
import CallingTone from "../../assets/toneRing.mp3";
import IncomingRingtone from "../../assets/incomingRingtone.mp3";
import io from 'socket.io-client';
import useStateAndRef from '../../hooks/useStateAndRef';
import renderCustomModalFunction, { triggerModalOverlayClick } from '../customModal/modalWrapper/renderCustomModalFunction';

import './voice.css';



function VoiceCall() {
  
  let {auth,sitesList,relevantSite,setSitesSockectsConnection} = useAuth();
  const nodeRef = useRef(null);
  const localStreamRef = useRef(null);
  const [peerId, setPeerId] = useState('');
  const remoteVideoRef = useRef(null);
  const[callConnections,setCallConnections] = useState(null);
  const currentUserVideoRef = useRef(null);
  const peerInstance = useRef(null);
  const voiceCallSocketRef = useRef(null);
  const [onCall,setonCall] = useState(false)
  const [userData,setUserData] = useState(null)
  const [tryCall,setTryCall] = useState(false);
  const [videoOn,setVideoOn]=useState(true);
  const [micOn,setMicOn]=useState(true);
  const [focousOnUserVideo,setFocousOnVideo]=useState(true);
  const [videoContainerSmall,setVideoContainerSmall]=useState(true);
  const [sitesSockectsConnectionObject,setSitesSockectsConnectionObject,refSiteSocketConnection]=useStateAndRef(null);
  const [shouldCloseCall, setShouldCloseCall] = useState(false);
  const [isAdminClickRejectVoiceCall,setIsAdminClickRejectVoiceCall]=useState(false);
  const [localStream,setLocalStream] = useState(null);
  const currentCall = useRef(null);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      console.log("USER REFRESHING WINDOW");
      //closeVoiceFromAdmin();
      e.returnValue = "";
    }

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const stopTracks = (videoRef) => {
    if (videoRef.current && videoRef.current.srcObject) {
      releaseTracks(videoRef.current.srcObject)
      videoRef.current.srcObject = null;
    }
  };

  const releaseTracks =(stream)=>{
    if(stream){
      const tracks = stream.getTracks();
      tracks.forEach(function(track) {
        if (track.readyState === 'live') {
          track.stop();
        }
      });
    }
  }

  const closeVoice =(isCancelActive= isAdminClickRejectVoiceCall,p_callConnections= callConnections ,p_tryCall=tryCall,p_localStream = localStream )=>{
    console.log("close voice ",isCancelActive)

    //close peer connection
    p_callConnections && p_callConnections.close();
    console.log(p_callConnections, p_localStream, p_tryCall);
    // release media connection
    if(p_localStream){
      releaseTracks(p_localStream)
      console.log("localStream released");
    }else{
      stopTracks(currentUserVideoRef);
    }

    stopTracks(remoteVideoRef);

    // send end call to user 
    if(isCancelActive){
      closeVoiceFromAdmin();
    }

    if(p_tryCall){
      cancelAdminTryCall();
      setTryCall(false);
    }
    triggerModalOverlayClick("incomingVoiceCall");


    setonCall(false);
    setCallConnections(null);

    // emit to all servers(sites list) that admin is available 
    sendAdminAvalability(true);
    resetVideoContainerStates();
  }

  const resetVideoContainerStates =()=>{
    setVideoContainerSmall(true);
    setFocousOnVideo(true);
  }

  const closeVoiceFromAdmin = () => {
    if (voiceCallSocketRef.current && voiceCallSocketRef.current.userData) {
        const targetSocket = voiceCallSocketRef.current.userData.socket;
        if (targetSocket) {
            voiceCallSocketRef.current.socket.emit('closeVoiceFromAdmin', targetSocket);
            console.log("Emitted closeVoiceFromAdmin for:", targetSocket);
        } else {
            console.error("Target socket is missing in userData:", voiceCallSocketRef.current.userData);
        }
    } else {
        console.error("voiceCallSocketRef is not properly initialized:", voiceCallSocketRef.current);
    }
};


  const cancelAdminTryCall = ()=>{
    if( voiceCallSocketRef.current){
      console.log('cancelling',voiceCallSocketRef.current);
      voiceCallSocketRef.current.socket.emit('cancelVoiceFromAdmin',voiceCallSocketRef.current.userData?.user_id);
    }
  }

  const adminNotAvailable = ()=>{
    if( voiceCallSocketRef.current){
      voiceCallSocketRef.current.socket.emit('adminNotAvailable',voiceCallSocketRef.current.userData.socket)
    }
  }

  const adminRecivedTheIncomingVoiceCall =()=>{
    if( voiceCallSocketRef.current){
      voiceCallSocketRef.current.socket.emit('adminRecivedTheIncomingVoiceCall',auth.userId);
    }
  }

  const conifrmVoiceCall = (call)=>{
    console.log("Manager answering the call...");
    navigator.mediaDevices.getUserMedia({  audio: true })
    .then((stream) => {
      console.log("Manager audio stream acquired:", stream);

      adminRecivedTheIncomingVoiceCall();
      setLocalStream(stream);
      call.answer(stream);
      currentUserVideoRef.current.srcObject = stream;
      setonCall(true);
      currentCall.current = call;
      console.log("answered call:", call);

      localStorage.setItem("remotePeerID", call.peer);

      call.on('error', ()=>{
        console.log("close from call.on('error' ");
        setShouldCloseCall(true);
      })

      call.on('stream', function(remoteStream) {
        console.log('Remote stream received:', remoteStream);
        remoteVideoRef.current.srcObject = remoteStream;
        call.peerConnection.oniceconnectionstatechange = function () {
          console.log("ICE connection state changed:", call.peerConnection.iceConnectionState, remoteStream, call);
        };

      });


      call.on('close', ()=>{
        console.log("disconnect");
      })


    })
    .catch((err) => {
      console.log("cant answer " + err);
      adminNotAvailable()
    });
  }

  const isAdminOnCall = ()=>{
    const adminOnCall = remoteVideoRef.current && remoteVideoRef.current.srcObject
    return adminOnCall; 
  }

  const incomingVoiceCall =(call)=>{

    setCallConnections(call);
    sendAdminAvalability(false);

    let userName = voiceCallSocketRef && voiceCallSocketRef.current && voiceCallSocketRef.current.userData &&voiceCallSocketRef.current.userData.name ? voiceCallSocketRef.current.userData.name :'Client';
    let siteName =voiceCallSocketRef && voiceCallSocketRef.current && voiceCallSocketRef.current.userData &&voiceCallSocketRef.current.userData.site ? voiceCallSocketRef.current.userData.site :'';

    const titleText = `${userName} is calling`;
    const bodyText = `site: ${siteName}`;

    const onConfirm = ()=>{
      conifrmVoiceCall(call);
    }

    const onCancle = (p_active=false)=>{
      console.log ("admin click cancel " ,p_active);
      setIsAdminClickRejectVoiceCall(p_active);
      setShouldCloseCall(true);

    };

    const options = {
      title: titleText,
      className :"incomingVoiceCall",
      bodyText: bodyText,
      id:"incomingVoiceCall",
      closeOnOutsideClick:false,
      buttons: [
        {
          text: "Decline",
          class: "dangerButton",
          callback:onCancle,
          icon:'f095',
          value: "cancel"
        },
        {
          text: "Answer call",
          class: "successButton",
          closeModal: true,
          icon :<i class="fa-duotone fa-phone"></i>,
          callback:onConfirm,
          value: "confirm"
        }
      ],
    };

    renderCustomModalFunction(options)
  }

  function handleCloseCall() {
    setShouldCloseCall(true);
 
  }
  
  function handleGetAdminService(relevant_socket) {
    const admin_name = auth && auth.userName ? auth.userName : "";
    const admin_id = auth && auth.userId ? auth.userId : "";
    const currentRemotePeerID = auth && localStorage.getItem("remotePeerID") ? localStorage.getItem("remotePeerID") : "";
    relevant_socket.emit('serviceAdminSocket', [peerId, admin_name, admin_id, currentRemotePeerID]);
  }
  
  function handleCallUserDetails(p_user_data, p_site_name, relevant_socket) {
    // const isAdminOnCallResponse = isAdminOnCall();
    // if (isAdminOnCallResponse) return;

    // Update user data with site information
    p_user_data.site = p_site_name;
    setUserData(p_user_data);

    voiceCallSocketRef.current = {
        socket: relevant_socket,
        userData: { ...p_user_data }, // Ensure userData is fully populated
    };

    console.log("Updated voiceCallSocketRef on handleCallUserDetails:", voiceCallSocketRef.current);
  }

  
  function handleUserCancelVoiceCall(userDetails) {
    const isAdminOnCallResponse =  isAdminOnCall();
    if(!isAdminOnCallResponse)
     //only if admin not alive with user we'll trigger this event
     setShouldCloseCall(true);

  }
  
  function handleAnotherAdminHandleTheVoiceCall() {
    const isAdminOnCallResponse =  isAdminOnCall();
    if(!isAdminOnCallResponse)
      //only if admin not alive with user we'll trigger this event
      console.log("Another admin handle the voice call , so remove incoming voice call")
      setShouldCloseCall(true);

  }
  
  function handlePeerUnavailable() {
    console.log("Peer unavailable");
  }
  
  function handleGetUserDetails(connectedUserArray,site_name,relevant_socket) {

    if (connectedUserArray) {
      console.log("-==-=-=-=-=-=-=-=-=-=-=-", connectedUserArray, "=--=-=-=-=-=-=-=-=-=-=-=-=-");
      setTryCall(true);
      call(connectedUserArray,site_name ,relevant_socket);
    }
  }
  
  function handleDisconnect() {
    console.log('disConnect');
  }
  
  function handleUserDisconnected() {
    setShouldCloseCall(true);
  }
  
  // send to all servers sites the admin availability - true if available for call and false if unavailable 
  const sendAdminAvalability = (p_isAvailable) => {
    
    let sites_scokets ;
    if(refSiteSocketConnection && refSiteSocketConnection.current){
      sites_scokets = Object.values(refSiteSocketConnection.current);
    }else{
      sites_scokets=Object.values(sitesSockectsConnectionObject);
    }
    if(sites_scokets){
      sites_scokets.forEach((relevant_socket)=>{
        relevant_socket.emit('adminAvailability',p_isAvailable)
      })
    }
  }

  const controlUserVideo = () => {
    if (currentUserVideoRef.current && currentUserVideoRef.current.srcObject) {
        const videoTrack = currentUserVideoRef.current.srcObject.getTracks().find(track => track.kind === 'video');
        if (videoTrack) {
            videoTrack.enabled = !videoOn;
            setVideoOn(!videoOn);
        }
    }
  }

  const controlUserMic = () => {
    if (currentUserVideoRef.current && currentUserVideoRef.current.srcObject) {
        const audioTrack = currentUserVideoRef.current.srcObject.getTracks().find(track => track.kind === 'audio');
        if (audioTrack) {
            audioTrack.enabled = !micOn;
            setMicOn(!micOn);
        }
    }
  }

  const call =  (connectedUserArray,p_site_name,p_relevant_socket) => {

    if(peerInstance.current && connectedUserArray.length > 0){

        const userDetailsCurrent ={
           name:connectedUserArray[0].userName,
           site:p_site_name,
           user_id:connectedUserArray[0].user_id
        };


        setUserData(userDetailsCurrent);

        voiceCallSocketRef.current = {
          socket:p_relevant_socket,
          userData:userDetailsCurrent
        };
        

        const adminData={
          socket:p_relevant_socket.id,
          name: auth?.userName
        }


        p_relevant_socket.emit("adminCallToUser",{user:connectedUserArray[0].user_id,admin:adminData});

        

        navigator.mediaDevices.getUserMedia({ audio: true})
        .then(function(stream) {
          setLocalStream(stream);
      
          connectedUserArray.forEach((user)=>{
             //Calling 
            const id = user.peerId;
            const call = peerInstance.current.call(id, stream);

            call.on('stream', function(remoteStream) { 
              setonCall(true);
              setCallConnections(call);
              setTryCall(false); 
              // update server that admin is calling back to passenger
              
              voiceCallSocketRef.current.userData.socket= user.socket;
              setUserData((prev) => ({
                ...prev,
                socket: user.socket,
              }));

              remoteVideoRef.current.srcObject = remoteStream;
              currentUserVideoRef.current.srcObject = stream;
            })
          })

         
        })
        .catch(function(err) {
            // not succeeded
          setTryCall(false);
          console.log("error: " + err);
        })
    }else{
      // not succeeded
      setTryCall(false);
      return ;
    }
  }

  //SOCKET CONFIGURATION
  useEffect(() => {
    if (peerId && sitesList) {
      console.log("Manager Peer ID:", peerId);

      const sites_scokets = {};
      let SiteList = auth.sites_list;
      
      const admin_name = auth && auth.userName ? auth.userName : "";
      const admin_id = auth && auth.userId ? auth.userId : "";
      const currentRemotePeerID = auth && localStorage.getItem("remotePeerID") ? localStorage.getItem("remotePeerID") : "";

      SiteList.forEach((site_name) => {
        let url;
        if (site_name !== 'localhost') {
          url = "https://" + site_name + '.carteav.com';
        } else {
          url = "http://localhost:8080";
        }
  
        const relevant_socket = io(url, { secure: true });
        sites_scokets[site_name] = relevant_socket;
  
        // Add event listeners using named functions
        relevant_socket.on('close_call', handleCloseCall);
        relevant_socket.on('getAdminService', ()=>handleGetAdminService(relevant_socket));
        relevant_socket.on('callUserDetails',(data)=> handleCallUserDetails(data,site_name,relevant_socket));
        relevant_socket.on('userCancelVoiceCall', handleUserCancelVoiceCall);
        relevant_socket.on('anotherAdminHandleTheVoiceCall', handleAnotherAdminHandleTheVoiceCall);
        relevant_socket.on('peer_unavailable', handlePeerUnavailable);
        relevant_socket.on('getUserDetails',(data)=> handleGetUserDetails(data,site_name,relevant_socket));
        relevant_socket.on('disconnect', handleDisconnect);
        relevant_socket.on('user_disconnected', handleUserDisconnected);
        
        // Emit events
        relevant_socket.emit('adminAvailability', true);
        relevant_socket.emit('serviceAdminSocket', [peerId, admin_name, admin_id, currentRemotePeerID]);
  
        setSitesSockectsConnection(sites_scokets);
        setSitesSockectsConnectionObject(sites_scokets);
      });
  
      return () => {
        // Remove all event listeners and disconnect sockets
        Object.values(sites_scokets).forEach((relevant_socket) => {
          relevant_socket.off('close_call', handleCloseCall);
          relevant_socket.off('getAdminService', handleGetAdminService);
          relevant_socket.off('callUserDetails', handleCallUserDetails);
          relevant_socket.off('userCancelVoiceCall', handleUserCancelVoiceCall);
          relevant_socket.off('anotherAdminHandleTheVoiceCall', handleAnotherAdminHandleTheVoiceCall);
          relevant_socket.off('peer_unavailable', handlePeerUnavailable);
          relevant_socket.off('getUserDetails', handleGetUserDetails);
          relevant_socket.off('disconnect', handleDisconnect);
          relevant_socket.off('user_disconnected', handleUserDisconnected);
          relevant_socket.disconnect();
        });
      };
    }
  }, [peerId, sitesList]);

  //PEER JS CONFIGURATION
  useEffect(() => {
    
    const iceConfiguration = {
      host: "peer.carteav.com",
      path: "/",
      debug: 3,
      secure: true, // Keep secure true for HTTPS
      port: 443, // Standard port for HTTPS
      config: {
        iceServers: [
          {
            "urls": "turn:peer.carteav.com:3478",
            "username": "carteav",
            "credential": "Carteav26"
          },
          {
            urls: 'stun:peer.carteav.com:3478'
          },
          {
            urls: 'stun:stun.l.google.com:19302'
          },
        ]
      }
    }
    
    const conn = new Peer(localStorage.getItem("peerID"), iceConfiguration);
    conn.on('data', (data) => {
      console.log("Data received!", data);
    });
    conn.on('connection', function(conn) {
      console.log("connection")
    });

    conn.on('open', (id) => {
      console.log("Manager: Peer connection established with ID:", id);
      if (localStorage.getItem("peerID") === null)
        {
          localStorage.setItem("peerID", id);
        }
      
      setPeerId(id);


      const remotePeerID = localStorage.getItem("remotePeerID");
      if (remotePeerID)
      {
        console.log("Reconnecting to prev remote", remotePeerID);
        //setTryCall(true);
      }
        
    });

      // incoming call 
      conn.on('call',(call) => {
      console.log("received a call:", call);
      console.log("current ongoing call:", currentCall.current);
      if (currentCall.current || localStorage.getItem('remotePeerID') === call.peer)
      {
        if (currentCall.current)
        {
          currentCall.current.close();
        }
        setCallConnections(call);
        console.log("Checking localStream", localStreamRef.current);
        if (localStreamRef.current)
        {
          releaseTracks(localStreamRef.current);
        }

        conifrmVoiceCall(call);
      }
      
      const isAdminOnCallResponse =  isAdminOnCall();
      //should be apeared pop up when admin is calling
        if(!localStorage.getItem("remotePeerID") && !isAdminOnCallResponse)
          incomingVoiceCall(call);
        
      })

      conn.on('error', (error) => {

        console.log('Error connecting:', error.type);

        if (error.type === "network" && conn.disconnected) {
          console.log("Client: Peer is disconnected, attempting to reconnect...");
            conn.reconnect();
        } else if(error.type === "unavailable-id")
        {
          console.log("id was taken, reconnecting with new peer");
          // Current peer id is probably taken, setting new peer id and refreshing the page, updating current call if needed
          conn.destroy();
          localStorage.removeItem('peerID');
          window.location.reload(true);
        }
        else {
            console.warn("Client: Peer is not in a disconnected state, cannot reconnect.");
        }
      });
      conn.on('disconnected', function() {
        console.log("Admin: Peer connection disconnected");
        conn.reconnect(); // Peer.js built-in reconnect
     });

    conn.on('close', () => {
      console.log('close peer')
    });
   
    peerInstance.current = conn;

    peerInstance.current.on('iceConnectionStateChange', (state) => {
      console.log('ICE Connection State:', state);
    });

    return () => {
      if (peerInstance.current) {
        peerInstance.current.disconnect();
      }
      
    };
  
  }, [])

  // validate the visibility of vide container

  useEffect(() =>{
    if(localStream)
    {
      localStreamRef.current = localStream;
      console.log("LOCAL STREAM IS CHANGED", localStream);
    }
  }, [localStream])
  useEffect(()=>{
    const videoContainer = document.getElementById('video-container')
    if(onCall || tryCall){
      videoContainer.style.visibility="visible"
      videoContainer.style.color="white"
    }else{
      videoContainer.style.visibility="hidden"
    }
  },[onCall,tryCall ])

  useEffect(() => {
    if (shouldCloseCall) {
      console.log("=================================== CURRENTLY IN SHOULD CLOSE VOICE ===========================================")
      closeVoice(isAdminClickRejectVoiceCall,callConnections,tryCall,localStream);
      setShouldCloseCall(false);
      setIsAdminClickRejectVoiceCall(false);
      currentCall.current = null;
      if(localStorage.getItem('remotePeerID'))
      {
        localStorage.removeItem('remotePeerID');
      }
    }
  }, [shouldCloseCall,callConnections,tryCall,onCall,isAdminClickRejectVoiceCall,localStream]);



  const playIcomingRingtone = callConnections && !onCall && !tryCall;
  
  return (
    <div  style={{zIndex:'9999999',position:'fixed',visibility:onCall ||tryCall ?'visible':'hidden'}}>
      {playIcomingRingtone && IncomingRingtone && <audio src={IncomingRingtone}  autoPlay />} 
      <Draggable
        nodeRef={nodeRef}
        defaultPosition={{ x:  0, y: window.innerHeight-225 }}
        bounds={{left:0 , right:window.innerWidth-458,bottom:window.innerHeight-225,top:0 }}
      >
        <div
          ref={nodeRef}
          className='video-container'
          id="video-container"
          style={{
            height:videoContainerSmall ?"120px":'400px'
          }}
          >
           
            <div >
              <div>
                {
                  tryCall && !onCall ?
                  <>
                    <div className='videoContainer_userName' style={{left:5}}>Calling to {userData?.name ? userData.name :'Client'}</div> 
                    <audio src={CallingTone}  autoPlay />
                  </>
                  :
                    <div className='videoContainer_userName'>
                      {userData?.name ? userData.name :'Client'}
                    </div> 
                }
              </div>
              <div className='videoContainer_site'>Site: {userData?.site ? userData.site :'Client'}</div> 
              <div 
                className='videoContainer_endCallButton' 
                onClick={(e)=>{
                  e.preventDefault();
                  e.stopPropagation();
                  setIsAdminClickRejectVoiceCall(true)
                  setShouldCloseCall(true);

                }}
                onTouchStart={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setIsAdminClickRejectVoiceCall(true);
                  setShouldCloseCall(true);
                }}
              />
                <div className='videoContainer_extandButton' onClick={()=>{setVideoContainerSmall(!videoContainerSmall)}} />
              </div>
              <div>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  visibility:!videoContainerSmall  && onCall  ?"visible":'hidden'
                }}>
                  <div
                  style={{
                      display: 'flex',
                      flexDirection: 'column',
                      position:'absolute',
                      top:focousOnUserVideo  ?'280px' : '74px',
                      right:focousOnUserVideo ?'130px':null,
                      width:'100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <div className="videoCall_name_header"> me</div> 
                    <video
                      onClick={()=>{
                        setFocousOnVideo(!focousOnUserVideo)
                      }} 
                      width={focousOnUserVideo  ? '90px':'240px'} 
                      height={focousOnUserVideo ? '75px':'170px'} 
                      style={{
                        marginTop:!focousOnUserVideo  ? '8px':'5px',
                        borderRadius:'8px',
                        position:'relative',
                        bottom:'20px',
                      }} 
                      ref={currentUserVideoRef}
                      autoPlay  
                      muted
                    />
                  </div>
                  <div 
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      position:'absolute',
                      top:focousOnUserVideo ?'74px' : '280px',
                      right:focousOnUserVideo ?null:'130px',
                      width:'100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <div className="videoCall_name_header">{userData?.name ? userData.name :'Client'}</div> 
                    <video
                      onClick={()=>{
                        setFocousOnVideo(!focousOnUserVideo)
                      }} 
                      style={{
                        marginTop:focousOnUserVideo  ? '8px':'5px',
                        borderRadius:'8px',
                        position:'relative',
                        bottom:'20px',
                        
                      }} 
                      width={!focousOnUserVideo  ? '90px':'240px'} 
                      height={!focousOnUserVideo ? '75px':'170px'} 
                      ref={remoteVideoRef}
                      autoPlay
                    />
                  </div>
                </div>
              
              <Stack sx={{
                  position:'absolute',
                  bottom:0,
                  width:'100%',
                  backgroundColor:'var(--black)',
                  display:'flex',
                  justifyContent: 'center',
                  height:'60px',
              }} 
              direction="row" 
              spacing={4}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent:'center',
                    alignItems: 'center',
                    opacity: 0.8,
                    position: 'relative',
                    bottom:6
                  }}
                >
                  <span
                    style={{
                      color:'white',
                      cursor: 'pointer'
                    }}
                    onClick={(e)=>{
                      e.preventDefault();
                      controlUserVideo()
                    }}
                  >
                    {videoOn ? <VideocamIcon  /> : <VideocamOffIcon   />}
                  </span>
                  <p style={{
                    top:'35px',
                    position: 'absolute',
                    fontSize:'0.6rem',
                    fontWeight:'bolder',
                    textAlign: 'center'
                  }}>
                  {videoOn ? 'Stop ' : 'Unstop '}

                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent:'center',
                    alignItems: 'center',
                    opacity: 0.8,
                    position: 'relative',
                    bottom:6
                    
                  }}
                >
                  <span
                    style={{
                      color:'white',
                      cursor: 'pointer'
                    }}
                  onClick={(e)=>{
                    e.preventDefault();
                    controlUserMic()
                  }}
                >
                  {micOn ? <MicIcon /> : <MicOffIcon />}

                </span>
                  <p style={{
                    top:'35px',
                    position: 'absolute',
                    fontSize:'0.6rem',
                    fontWeight:'bolder',
                    textAlign: 'center'
                  }}>
                  {micOn ? 'Mute' : 'Unmute'}

                  </p>
                </div>
                </Stack>
                </div>
            </div>
        </Draggable> 
    </div>
  );
}

export default VoiceCall;


